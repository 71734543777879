import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/PB76wdh1Pp8"
    bibleGroupSrc="https://www.docdroid.net/vC8MPLt/bible-group-homework-6-28-pdf"
    bibleGroupText="Bible Group Homework"
    notesSrc="https://www.bible.com/events/21919823"
    notesText="Sermon Notes"
  >
    <SEO title="Am I a Christian? - Colossians" />
  </Layout>
)

export default SermonPost
